import { notification, Modal } from "antd";
import React, { useEffect, useState } from "react";

import useCheckout from "./useHooks/useCheckout";
import { CloseCircleFilled } from "@ant-design/icons";

import "./styles/checkout.css";

import { useNavigate } from "react-router-dom";

const Checkout = ({ checkPlaceOrder, totalCost, deliveryInfo, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { isSuccess, postData } = useCheckout();

  const showErrorNotification = () => {
    notification.open({
      message: "Failed",
      description: "Something went wrong. Please try again.",
      icon: <CloseCircleFilled style={{ color: "red" }} />,
      placement: "top",
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handlePost();
  };

  useEffect(() => {
    if (isSuccess === "success") {
      navigate("/success");
      localStorage.removeItem("selectedSeats");
      setIsModalOpen(false);
    } else if (isSuccess === "failed") {
      showErrorNotification();
    }
  }, [isSuccess]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePost = () => {
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      postalCode,
      contact,
    } = deliveryInfo;
    const data = {
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
      userContact: contact,
      userAddress: address,
      userCity: city,
      state: state,
      postalCode: postalCode,
      purchasedSpotLabels: label,
      totalAmount: totalCost,
      paymentMethod: "Admin Received",
      paymentStatus: "success",
    };

    const url = "https://api.iioc.com/api/purchase/checkout";

    postData(url, data);
  };

  return (
    <div className="checkout-parent">
      <button
        className={`sc-bdvvtL jPQkWg sc-iboAsT sc-bcCSHH hEyZIb cRkVpC  noFocus place-order ${
          checkPlaceOrder() ? "" : "disabled"
        } `}
        onClick={() => showModal()}
      >
        Proceed To Pay
      </button>

      <br />
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-text"
      >
        <p style={{ fontSize: "14px !important" }}>Will you like to proceed</p>
      </Modal>
    </div>
  );
};

export default Checkout;
