import React, { useState, useMemo } from "react";
import { Card, Checkbox, Table, Divider } from "antd";
import "./styles/orderSummary.css";
import Checkout from "./Checkout";
import signinbg from "../components/image/img.png";
import { totalCost, taxAmount } from "./utils/helpers";

const OrderSummary = ({ product, isAllFieldsFilled, deliveryInfo }) => {
  const [isChecked, setChecked] = useState(true);

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const checkPlaceOrder = () => {
    return isAllFieldsFilled;
  };

  let cost = totalCost(product);

  // Calculate the total amount with or without tax based on the checkbox state
  const total = useMemo(() => {
    return isChecked ? cost + taxAmount(cost) : cost;
  }, [cost, isChecked]);

  return (
    <div className="parent-order">
      <Card hoverable className="order-card" title="Selected Prayer Space">
        <br />

        <div style={{ paddingLeft: 24, paddingRight: 25 }}>
          {product.map((val) => {
            return (
              <span
                key={val.id}
                style={{
                  display: "block",
                  fontSize: 13,
                  paddingBottom: 10,
                }}
              >
                {val.label} ({val.spotRow}, {val.spotColumn})
                <span className="cost">${val.cost}</span>
              </span>
            );
          })}
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <Checkbox onChange={onChange} checked={isChecked}>
              <p style={{ fontWeight: "normal" }}>2.5% Bank Charges</p>
            </Checkbox>

            <p className="cost">{isChecked ? "$" + taxAmount(cost) : "0"}</p>
          </div>
          <Divider />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 24,
            fontSize: 18,
            fontWeight: "bold",
            paddingRight: 24,
          }}
        >
          <h4> Total:</h4>
          <h4>${total}</h4>
        </div>

        <Checkout
          checkPlaceOrder={checkPlaceOrder}
          totalCost={total}
          deliveryInfo={deliveryInfo}
          label={product.map((val) => val.label)}
        />
      </Card>

      <br />
      <Card
        hoverable
        className="order-card"
        style={{ padding: 20 }}
        cover={
          <img src={signinbg} alt="" style={{ width: "100%", height: 250 }} />
        }
      ></Card>
    </div>
  );
};

export default OrderSummary;
