import { Button, Tag } from "antd";
import React from "react";
import { SEAT_STATUS } from "../layout/layout";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./styles/SeatPopup.css";

const isClickedInside = (e, element) => {
  let node = e.target;
  while (node) {
    if (node === element) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const Popup = ({
  position,
  seat,
  onClose,
  isSelected,
  onSelect,
  onDeSelect,
}) => {
  const containerRef = React.useRef(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, []);

  React.useEffect(() => {
    const onClick = (e) => {
      if (isClickedInside(e, containerRef.current)) {
        onClose();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  const handleSelect = () => {
    if (!isSelected) {
      return onSelect(seat);
    } else {
      return onDeSelect(seat);
    }
  };

  return (
    <div
      ref={containerRef}
      className="tooltip"
      style={{
        position: "absolute",
        top: position.y - 25 + "px",
        left: position.x - dimensions.width / 2 + "px",
        padding: "10px",
        backgroundColor: "#D3D3D3",
        borderColor: "#D3D3D3",
        width: "220px",
        borderRadius: "3px",
        boxShadow: "10px 13px 11px -3px rgba(0,0,0,0.75)",
        zIndex: 10,
      }}
    >
      <div className="tooltiptext">
        <div className="tool-tip-header">
          <span>Seat: {seat.label} &nbsp; </span>
          <div
            style={{
              fontWeight: "Bold",
              display: "inline",
            }}
          >
            ${seat.cost}
          </div>
          <div>
            <CloseCircleOutlined
              onClick={() => onClose()}
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "3%",
                right: " 3%",
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          {isSelected && seat.spotStatus !== SEAT_STATUS.PENDING ? (
            <Tag color="#b3b300">Selected</Tag>
          ) : seat.spotStatus === SEAT_STATUS.RESERVED ? (
            <Tag color="#5a5a5a">Reserved</Tag>
          ) : !isSelected &&
            (seat.spotStatus !== SEAT_STATUS.PENDING ||
              seat.spotStatus == SEAT_STATUS.VACANT) ? (
            <Tag color="#87d068">Available</Tag>
          ) : (
            <Tag color="green">Pending</Tag>
          )}
          {(isSelected || seat.spotStatus === SEAT_STATUS.VACANT) && (
            <Button
              type="primary"
              onClick={handleSelect}
              style={{ padding: "0 0.3rem" }}
            >
              {isSelected ? "Unselect Space" : " Select Space"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
