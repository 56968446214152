export const totalCost = (seats) => {
  let a = seats?.reduce((acc, val) => acc + val.cost, 0);

  return a;
};

export const taxAmount = (total) => {
  return (2.5 / 100) * total;
};

export const isTimeDifferenceGreaterThan10Minutes = () => {
  if (localStorage.getItem("currentDate")) {
    const differenceInMilliseconds = Math.ceil(
      new Date() - new Date(localStorage.getItem("currentDate").toString())
    );

    const t = differenceInMilliseconds / 60000;
    return t > 10;
  }
};
