import { useState } from "react";
import axios from "axios";

const useCheckout = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isSuccess, setSuccess] = useState(false);

  const postData = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      setResponse(response.data);
      setSuccess("success");
      setError(null);
    } catch (error) {
      setError(error);
      setResponse(null);
      setSuccess("failed");
    }
  };

  return { isSuccess, postData };
};

export default useCheckout;
