import React from "react";
import { Badge, Button } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";

const BadgeButton = ({ cartItems, handleClick }) => {
  return (
    <Badge count={cartItems | 0}>
      <Button
        style={{ backgroundColor: "#0B577E" }}
        type="primary"
        onClick={() => handleClick()}
      >
        My Selection
      </Button>
    </Badge>
  );
};

export default BadgeButton;
