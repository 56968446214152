import React from "react";
import "./styles/cart.css";
import { useNavigate } from "react-router-dom";
import { Empty } from "antd";
import CartComponent from "./card";
import { totalCost } from "./utils/helpers";

const Cart = ({ seats, handleDeselect }) => {
  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate("/checkout", {
      state: {
        product: seats,
      },
    });
  };
  return (
    <div className="parent-cart">
      {seats.length <= 0 && (
        <Empty
          style={{ marginTop: 90 }}
          description={<b>No Space Selected</b>}
        />
      )}

      <div className="cartlist">
        {seats?.map((seat) => {
          return (
            <CartComponent
              key={seat.id}
              {...seat}
              handleDeselect={handleDeselect}
            />
          );
        })}
      </div>

      {seats.length > 0 && (
        <div class="sc-eSJyHI ksGyxT">
          <div class="sc-kQoPux sNUgR">
            <div class="sc-ekRyGy gejECp">
              <div class="sc-fFeiMQ bCvzDL text text--dark text--primary sc-kiwPtn jSsuXk">
                Subtotal
              </div>
              <div class="sc-fFeiMQ bCvzDL text text--dark text--primary sc-kiwPtn jSsuXk">
                ${totalCost(seats)}
              </div>
            </div>

            <br />
          </div>
          <div class="sc-eDtABA zbTqY">
            <button
              class="sc-bdvvtL jPQkWg sc-iboAsT sc-bcCSHH hEyZIb cRkVpC  noFocus"
              onClick={handleCheckout}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
