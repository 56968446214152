import { Card, Col, Row, Divider } from "antd";
import "./styles/card.css";

const CartComponent = ({ label, spotRow, spotColumn, cost, handleDeselect }) => {
 
  return (
    <div data-bdd="ticket-stub-list" class="sc-hctura ffKjVh">
      <Card hoverable>
        <div data-bdd="ticket-stub-content">
          <div class="tooltip__content" data-bdd="ticket-stub-seat-info">
            <div class="tooltip__srs srs">
              <div class="srs__set">
                <span class="srs__label" data-bdd="stub-sec-label">
                  Space
                </span>
                <span class="srs__value" data-bdd="stub-sec-value">
                  {label}
                </span>
              </div>
              <div class="srs__set">
                <span class="srs__label" data-bdd="stub-row-label">
                  Row
                </span>
                <span class="srs__value" data-bdd="stub-row-value">
                  {spotRow}
                </span>
              </div>
              <div class="srs__set">
                <span class="srs__label" data-bdd="stub-seat-label">
                  Column
                </span>
                <span class="srs__value" data-bdd="stub-seat-value">
                  {spotColumn}
                </span>
              </div>
            </div>
          </div>
          <p style={{ paddingLeft: 20, paddingRight: 20, height: "0px" }}>
            <Divider />
          </p>
          <div>
            <div class="split-content tooltip__content">
              <div class="tooltip__nudge"></div>
              <div class="ticket-bag__ticket-description split-content__primary">
                <div class="clickable-ticket-type-description">
                  <span id="option-name-000000000001"></span>
                </div>
              </div>
              <div class="sc-cCKzRf fuacRE split-content__secondary ticket-bag__ticket-price">
                <div class="sc-cnTVOG gbMHqu">
                  <div>
                    <div
                      data-bdd="ticket-stub-seat-price"
                      class="offer-price ticket-bag__ticket-offer-price"
                    >
                      ${cost}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg
          data-bdd="ticket-bag-remove-btn"
          class="sc-jEieoE hJThSn"
          xmlns="http://www.w3.org/2000/svg"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          onClick={() => handleDeselect({label:label})}
        >
          Remove
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              fill="#262626"
              fill-opacity=".65"
              fill-rule="nonzero"
              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M11.5,5 C11.5,4.72385763 11.7238576,4.5 12,4.5 C12.2761424,4.5 12.5,4.72385763 12.5,5 L12.5,19 C12.5,19.2761424 12.2761424,19.5 12,19.5 C11.7238576,19.5 11.5,19.2761424 11.5,19 L11.5,5 Z M5,12.5 C4.72385763,12.5 4.5,12.2761424 4.5,12 C4.5,11.7238576 4.72385763,11.5 5,11.5 L19,11.5 C19.2761424,11.5 19.5,11.7238576 19.5,12 C19.5,12.2761424 19.2761424,12.5 19,12.5 L5,12.5 Z"
            ></path>
          </g>
        </svg>
      </Card>
    </div>
  );
};

export default CartComponent;
