import React from "react";
import { Group } from "react-konva";
import {
  IMAM_SEAT,
  SEATS_DISTANCE,
  SUBSECTION_PADDING,
} from "../layout/layout";
import Seat from "./Seat";

const SubSection = ({
  width,
  x,
  y,
  data,
  onHoverSeat,
  isPopup,
  onSelectSeat,
  onDeselectSeat,
  popupSeat,
  selectedSeatId,
  image,
  filterStatus,
}) => {
  return (
    <Group x={x} y={y + SUBSECTION_PADDING + 10}>
      <Group x={0} y={0}>
        {Object.keys(data.seats_by_rows).map((rowKey, rowIndex) => {
          const row = data.seats_by_rows[rowKey];

          const _xPos = (width - row.length * SEATS_DISTANCE) / 2;

          return (
            <React.Fragment key={rowKey}>
              {row.map((seat, seatIndex) => {
                const xPosition =
                  seat.label === IMAM_SEAT
                    ? (width - 90) / 2
                    : seatIndex * SEATS_DISTANCE;

                return (
                  <Seat
                    key={seatIndex}
                    x={_xPos + xPosition}
                    y={rowIndex * SEATS_DISTANCE}
                    data={seat}
                    image={image}
                    sectionWidth={width}
                    filter={filterStatus}
                    isPopup={isPopup}
                    onHover={onHoverSeat}
                    onSelect={onSelectSeat}
                    popupSeat={popupSeat}
                    onDeselect={onDeselectSeat}
                    isSelected={selectedSeatId?.some(
                      (e) => e.label === seat.label
                    )}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </Group>
    </Group>
  );
};

export default SubSection;
