import React, { useCallback, useState, useEffect } from "react";
import SeatPopup from "./SeatPopup";
import Section from "./Section";
import { Button, Drawer, Dropdown, Tooltip, notification } from "antd";
import axios from "axios";
import { Layer, Stage } from "react-konva";
import * as layout from "../layout/layout";
import Cart from "./Cart";
import "./styles/mainStageStyles.css";
import Legend from "./Legend";
import {
  InfoCircleOutlined,
  InfoCircleFilled,
  DownOutlined,
} from "@ant-design/icons";
import BadgeButton from "./BadgeButton";
import { isTimeDifferenceGreaterThan10Minutes } from "../components/utils/helpers";

const URL = "https://api.iioc.com/api";

const submitButtonId = "neon-pop-button6779c953ed584294a12c50b836dbbb65";

let resizeTimeout;

const MainStage = () => {
  const [jsonData, setJsonData] = useState([]);
  const [apiError, setApiError] = useState("");
  const containerRef = React.useRef(null);
  const stageRef = React.useRef(null);
  const [filterStatus, setFilterStatus] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [sections, setSections] = React.useState(null);
  const [size, setSize] = React.useState({
    width: 1000,
    height: 1000,
  });
  const [open, setOpen] = useState(false);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const divElement = document.getElementById("test-123");
  React.useEffect(() => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [selectedSeatId, setSelectedSeatId] = React.useState([]);

  const [popup, setPopup] = React.useState({ seat: null, pos: { x: 0, y: 0 } });

  React.useEffect(() => {
    function handleResize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        fetchData(false);
      }, 500);
    }
    window.addEventListener("resize", handleResize);
  });

  const onFilterButtonClick = ({
    item: {
      props: { value },
    },
  }) => {
    setFilterStatus(value);
  };

  const fetchData = useCallback(
    async (fetchData = true) => {
      try {
        let data = { data: jsonData };
        if (fetchData) {
          // data = await axios.get(`./seats-data.json`);

          data = await axios.get(`${URL}/spot`);

          setJsonData(data.data);
        }
        const seatsSectionsObj = {
          [layout.SPACE_TYPE.IMAM]: {},
          [layout.SPACE_TYPE.MAIN]: {},
          [layout.SPACE_TYPE.WOMEN]: {},
        };
        if (data.data) {
          for (const seat of data.data) {
            const seatLabelArr = seat.label.split("");

            if (!seatsSectionsObj[seatLabelArr[0]][seat.spotRow]) {
              seatsSectionsObj[seatLabelArr[0]][seat.spotRow] = [];
            }
            seatsSectionsObj[seatLabelArr[0]][seat.spotRow].push({
              name: seat.spotColumn,
              ...seat,
            });
          }

          const _sections = Object.keys(seatsSectionsObj).map((spaceType) => {
            return {
              name: spaceType,
              seats_by_rows: seatsSectionsObj[spaceType],
            };
          });

          let maxHeight = layout.SECTION_TOP_PADDING;
          _sections.forEach((section) => {
            maxHeight +=
              layout.getSubsectionHeight(section) * 1 +
              layout.SECTIONS_MARGIN / 2;
          });

          const newSize = {
            width:
              containerRef?.current?.offsetWidth < 1260
                ? 1260
                : containerRef?.current?.offsetWidth,
            height: maxHeight,
          };
          if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
          }
          setSections(_sections);
        }
      } catch (error) {
        setApiError("Failed to load data");
      }
    },
    [setSize, size.height, size.width]
  );

  useEffect(() => {
    const storedSeats = localStorage.getItem("selectedSeats");

    if (document.getElementById(submitButtonId)) {
      document.getElementById(submitButtonId).disabled = true;
    }

    if (storedSeats) {
      const parsedSeats = JSON.parse(storedSeats);
      setSelectedSeatId(parsedSeats);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    const image = new window.Image();
    image.src = "/assets/prayer-tile.jpeg";
    const handleLoadedImage = () => {
      setImage(image);
    };
    image.addEventListener("load", handleLoadedImage);

    return () => {
      image.removeEventListener("load", handleLoadedImage);
    };
  }, []);

  let lastSectionPosition = 20;

  const handleHover = React.useCallback((seat, pos) => {
    setPopup({
      seat: seat,
      position: pos,
    });
  }, []);

  const onCanvasScroll = useCallback(() => {
    if (popup.seat) {
      handleHover(
        popup.seat,
        stageRef.current.findOne(`#${popup.seat.label}`)?.getAbsolutePosition()
      );
    }
  }, [handleHover, popup.seat]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message) => {
    api.info({
      message: "Already Reserved",
      description: message,
      placement: "topRight",
      duration: 2,
      icon: <InfoCircleOutlined style={{ color: "#0B577E" }} />,
    });
  };

  const handleSelect = React.useCallback((seat, pos) => {
    setPopup({
      seat: seat,
      position: pos,
    });
  }, []);

  React.useEffect(() => {
    if (divElement) {
      divElement.scrollLeft =
        dimensions.width - stageRef.current?.getContainer().scrollLeft;
    }
  }, [dimensions, divElement]);

  React.useEffect(() => {
    if (divElement) {
      divElement.removeEventListener("scroll", onCanvasScroll);
      divElement.addEventListener("scroll", onCanvasScroll);
    }
  }, [onCanvasScroll, divElement]);

  const handleSelectSpace = React.useCallback(async (obj) => {
    try {
      const response = await axios.put(`${URL}/spot/reserve`, [
        {
          label: obj.label,
        },
      ]);
    } catch (error) {
      return openNotification("This space is already reserved");
    }

    if (isTimeDifferenceGreaterThan10Minutes()) {
      localStorage.removeItem("currentDate");
      fetchData();
    }

    if (!localStorage.getItem("currentDate")) {
      const currentDate = new Date();
      localStorage.setItem("currentDate", currentDate.toISOString());
    } else {
    }

    const objWithPendingLabel = { ...obj, spotStatus: "PENDING" };
    setSelectedSeatId((current) => [...current, objWithPendingLabel]);

    if (window.openPopModal) {
      window.openPopModal();
    }

    // Retrieve the existing array from localStorage
    const storedSeats = localStorage.getItem("selectedSeats");
    const parsedSeats = storedSeats ? JSON.parse(storedSeats) : [];

    // Add the new object to the array
    const updatedSeats = [...parsedSeats, objWithPendingLabel];

    // Store the updated array in localStorage
    localStorage.setItem("selectedSeats", JSON.stringify(updatedSeats));
  }, []);

  const handleDeselect = React.useCallback(async ({ label }) => {
    try {
      const response = await axios.put(`${URL}/spot/vacate`, [
        {
          label: label,
        },
      ]);
    } catch (error) {
      return openNotification("Failed to vacate the slot");
    }

    setSelectedSeatId((prevSelectedSeatId) =>
      prevSelectedSeatId.filter((seat) => seat.label !== label)
    );

    setSelectedSeatId((prevSelectedSeatId) =>
      prevSelectedSeatId.map((seat) =>
        seat.label === label ? { ...seat, spotStatus: "VACANT" } : seat
      )
    );

    // Retrieve the existing array from localStorage

    const storedSeats = localStorage.getItem("selectedSeats");
    const parsedSeats = storedSeats ? JSON.parse(storedSeats) : [];

    // Remove the deselected seat from the array
    const updatedSeats = parsedSeats.filter((seat) => seat.label !== label);

    // Store the updated array in localStorage
    localStorage.setItem("selectedSeats", JSON.stringify(updatedSeats));
    fetchData();
  }, []);

  if (jsonData === null) {
    return <div ref={containerRef}>Loading...</div>;
  }

  if (apiError) return <h2 style={{ textAlign: "center" }}>{apiError}</h2>;

  const legend = (
    <div>
      <Legend />
    </div>
  );

  const menu = [
    {
      label: "All",
      value: "",
      key: "1",
    },
    {
      label: "Available",
      key: "2",
      value: "VACANT",
    },
    {
      label: "Selected",
      key: "3",
      value: "PENDING",
    },
    {
      label: "Reserved",
      key: "4",
      value: "RESERVED",
    },
  ];

  const getLabel = (value) => {
    return menu.find((item) => item.value === value).label;
  };

  const calculatePosition = () => {
    if (stageRef.current?.getContainer().scrollLeft) {
      return {
        x: popup.position.x - stageRef.current?.getContainer().scrollLeft,
        y: popup.position.y,
      };
    } else {
      return {
        x: popup.position.x,
        y: popup.position.y,
      };
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "lightgrey",
        flexWrap: "wrap",
      }}
    >
      {contextHolder}
      <div
        style={{
          position: "relative",
          backgroundColor: "lightgrey",
          width: "100%",
          height: "100%",
        }}
        ref={containerRef}
      >
        <div className="nav-header">
          {/* <Legend /> */}
          <div style={{ margin: "auto 0" }}>
            <Tooltip title={legend} color="#fff" trigger="click">
              <InfoCircleFilled
                style={{ color: "#0B577E", fontSize: "20px" }}
              />
            </Tooltip>
          </div>
          <div>
            <Dropdown
              menu={{
                items: menu,
                onClick: onFilterButtonClick,
                selectable: true,
                defaultSelectedKeys: ["1"],
              }}
              trigger={["click"]}
            >
              <Button type="primary" style={{ padding: "0 10px" }}>
                {getLabel(filterStatus)} <DownOutlined />
              </Button>
            </Dropdown>
          </div>

          <div>
            <BadgeButton
              handleClick={showDrawer}
              cartItems={selectedSeatId.length}
            />
          </div>
          <Drawer
            title="Selected Prayer Space"
            placement="right"
            headerStyle={{ textAlign: "center" }}
            width={500}
            onClose={onClose}
            open={open}
          >
            <Cart seats={selectedSeatId} handleDeselect={handleDeselect} />
          </Drawer>
        </div>
        <Stage
          id="test-123"
          ref={stageRef}
          width={size.width}
          height={size.height}
          scale={{ x: 1, y: 1 }}
          style={{ overflowX: "scroll", overflowY: "hidden" }}
        >
          <Layer>
            {sections?.map((section, index) => {
              const height = layout.getSubsectionHeight(section);
              const position = lastSectionPosition;
              lastSectionPosition = position + height * 1;

              return (
                <Section
                  x={size.width - (size.width - layout.SUBSECTION_WIDTH + 50)}
                  y={position}
                  height={height * 1 + layout.SECTIONS_MARGIN}
                  width={size.width}
                  key={index}
                  section={section}
                  image={image}
                  popupSeat={popup.seat}
                  filter={filterStatus}
                  isPopup={popup}
                  selectedSeatId={selectedSeatId}
                  onHoverSeat={handleHover}
                  onSelectSeat={handleSelect}
                  onDeselectSeat={handleDeselect}
                />
              );
            })}
          </Layer>
        </Stage>

        {popup.seat && (
          <SeatPopup
            position={calculatePosition()}
            seat={popup.seat}
            onClose={() => {
              setPopup({ seat: null });
              if (divElement) {
                divElement.removeEventListener("scroll", onCanvasScroll);
              }
            }}
            isSelected={selectedSeatId?.some(
              (e) => e.label === popup.seat.label
            )}
            onDeSelect={handleDeselect}
            onSelect={handleSelectSpace}
          />
        )}
      </div>
    </div>
  );
};

export default MainStage;
