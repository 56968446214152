import "./styles/failed.css";

const Failed = () => {
  return (
    <div style={{ minHeight: "70vh" }}>
      <div className="failed-card-container">
        <div className="card">
          <div className="circle">
            <i className="checkmark">❌</i>
          </div>
          <h1 className="h1">Failed</h1>
          <p>Your request not processed!</p>
        </div>
      </div>
    </div>
  );
};

export default Failed;
