export const SEAT_WIDTH = 15;
export const SEAT_HEIGHT = 20;
export const SEATS_DISTANCE = 25;
export const SUBSECTION_PADDING = 30;
export const SUBSECTION_WIDTH = 200;

export const SECTIONS_MARGIN = 20;
export const SECTION_TOP_PADDING = 40;

export const IMAM_SEAT = "I11A";
export const SPECIAL_IMAM_SEAT = "I001";

export const SPACE_TYPE = {
  IMAM: "I",
  MAIN: "M",
  WOMEN: "W",
};

export const SEAT_STATUS = {
  VACANT: "VACANT",
  PENDING: "PENDING",
  RESERVED: "RESERVED",
};

export const getSubsectionWidth = (subsection) => {
  const rows = Object.keys(subsection.seats_by_rows);
  const maxRows = Math.max(
    ...rows.map((r) => Object.keys(subsection.seats_by_rows[r]).length)
  );
  return SEATS_DISTANCE * maxRows + SUBSECTION_PADDING * 2;
};

export const getMaxSectionWidth = (sections) => {
  return Math.max(...sections.map((section) => getSubsectionWidth(section)));
};

export const getSubsectionHeight = (subsection) => {
  const rows = Object.keys(subsection.seats_by_rows);
  return SEATS_DISTANCE * rows.length + SUBSECTION_PADDING * 2;
};
