import "./styles/success.css";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/");
  };

  const HoverIconButton = () => {
    return (
      <Button
        className="hover-icon-button"
        shape="circle"
        onClick={handleButtonClick}
      >
        <ArrowLeftOutlined className="icon" />
      </Button>
    );
  };
  return (
    <div style={{ minHeight: "70vh" }}>
      <div className="success-card-container">
        <div className="success-card">
          <div className="success-circle">
            <i className="success-checkmark">✓</i>
          </div>
          <h1 className="success-h1">Success</h1>
          <p>We received your purchase request; we'll be in touch shortly!</p>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <HoverIconButton />
            <p style={{ marginTop: 10, marginLeft: 5 }}>
              Back To Prayer Selection Space
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
