import "./App.css";

import "antd/dist/reset.css";
import { Route, HashRouter as Router, Routes } from "react-router-dom";

import CheckoutOutContainer from "./components/CheckoutContainer";
import Failed from "./components/Failed";
import MainStage from "./components/MainStage";
import Success from "./components/Success";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<MainStage />} />
          <Route exact path="checkout/" element={<CheckoutOutContainer />} />
          <Route exact path="success/" element={<Success />} />
          <Route exact path="failed/" element={<Failed />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
