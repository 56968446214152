import React, { useEffect, useState } from "react";
import { Circle, Group } from "react-konva";
import { SEAT_STATUS, SEAT_WIDTH, SPECIAL_IMAM_SEAT } from "../layout/layout";

const Seat = (props) => {
  const isReserved = props.data.spotStatus === SEAT_STATUS.RESERVED;
  const isPending = props.data.spotStatus === SEAT_STATUS.PENDING;

  const [isSeatVisible, setIsSeatVisible] = useState(true);

  useEffect(() => {
    setIsSeatVisible(
      !(
        (props.filter === SEAT_STATUS.RESERVED && !isReserved) ||
        (props.filter === SEAT_STATUS.VACANT && isReserved) ||
        (props.filter === SEAT_STATUS.PENDING &&
          (isReserved || !props.isSelected))
      )
    );
  }, [isReserved, props.isSelected, props.filter]);

  const onClick = (e) => {
    if (!props.isPopup.seat) {
      props.onHover(props.data, e.target.getAbsolutePosition());
      const container = e.target.getStage().container();
      if (isReserved) {
        container.style.cursor = "not-allowed";
      } else {
        container.style.cursor = "pointer";
      }
    } else if (props.isPopup.seat.label !== props.data.label) {
      props.onHover(props.data, e.target.getAbsolutePosition());
      const container = e.target.getStage().container();
      if (isReserved) {
        container.style.cursor = "not-allowed";
      } else {
        container.style.cursor = "pointer";
      }
    } else {
      props.onHover(null);
      const container = e.target.getStage().container();
      container.style.cursor = "";
    }
  };

  const updatedSeatWidth =
    props.data.label === SPECIAL_IMAM_SEAT ? SEAT_WIDTH + 10 : SEAT_WIDTH;

  return (
    <Group x={props.x} y={props.y} onClick={onClick} id={props.data.label}>
      <Circle
        x={0}
        y={0}
        radius={updatedSeatWidth / 2}
        fill={isReserved ? "#d4d4d4" : "#0B577E"}
        strokeWidth={1}
        visible={isSeatVisible}
        opacity={1}
        preventDefault={false}
      />

      {!isReserved && props.isSelected && (
        <Circle
          x={0}
          y={0}
          radius={updatedSeatWidth / 1.25}
          stroke={isSeatVisible ? "#0B577E" : ""}
          strokeWidth={3}
          listening={false}
        />
      )}
      {props.popupSeat?.label === props.data.label && !props.isSelected && (
        <Circle
          x={0}
          y={0}
          radius={updatedSeatWidth / 2}
          visible={isSeatVisible}
          fill="#e8ce0c"
          strokeWidth={1}
          listening={false}
        />
      )}

      {isPending && !props.isSelected && (
        <Circle
          x={0}
          y={0}
          radius={updatedSeatWidth / 2}
          fill={"#d4d4d4"}
          strokeWidth={3}
          listening={false}
        />
      )}
    </Group>
  );
};

export default Seat;
