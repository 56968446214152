import { useReducer, useMemo } from "react";
import CheckoutComponent from "./Form";
import OrderSummary from "./orderSummary";
import { useLocation } from "react-router-dom";
import "./styles/checkoutContainer.css";
import { initialState, reducer } from "./reducer/formReducer";

const CheckoutOutContainer = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = (e) => {
    const action = { property: e.target.name, value: e.target.value };
    dispatch({ type: "SET_VALUES", action });
  };

  const isAllFieldsFilled = useMemo(() => {
    return Object.values(state).every((value) => !!value);
  }, [state]);

  return (
    <div className="parent-checkout">
      <CheckoutComponent onChange={onChange} state={state} />

      <OrderSummary
        {...location.state}
        isAllFieldsFilled={isAllFieldsFilled}
        deliveryInfo={state}
      />
    </div>
  );
};

export default CheckoutOutContainer;
